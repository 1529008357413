import * as React from "react";

import "./style.css";
import "../../styles/global.css";
import logo from "../../images/logo.png";

export default function Header() {
  return (
    <>
      <header>
        <img src={logo} width="200px" />
        <div className="title">spark joy.</div>
        <div className="subtitle">SATURDAY 04 16 2022</div>
      </header>
    </>
  );
}
