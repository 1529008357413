import * as React from "react";

import "./style.css";
import "../../styles/global.css";

export default function Body() {
  return (
    <>
      <div>
        <svg
          id=""
          preserveAspectRatio="xMidYMax meet"
          class="svg-separator"
          viewBox="0 0 1600 100"
          data-height="100"
        >
          <path
            d="M-40,95.6C20.3,95.6,20.1,56,80,56s60,40,120,40s59.9-40,120-40s60.3,40,120,40s60.3-40,120-40
s60.2,40,120,40s60.1-40,120-40s60.5,40,120,40s60-40,120-40s60.4,40,120,40s59.9-40,120-40s60.3,40,120,40s60.2-40,120-40
s60.2,40,120,40s59.8,0,59.8,0l0.2,143H-60V96L-40,95.6z"
            fill="#758BFD"
          ></path>
        </svg>
      </div>
      <div className="body">
        <div className="overview">
          <h1>Overview</h1>
          <p>
            Spark Joy is a one-day UX/UI designathon hosted by Penn Spark.
            During this competition, students collaborate in teams of 2 to
            address a design challenge. Participants will have the opportunity
            to build prototypes, attend workshops, and win awards & prizes!
          </p>
          <a
            target="_blank"
            href="https://www.eventbrite.com/e/spark-joy-a-uiux-designathon-registration-301478830297?ut[%E2%80%A6]tm-medium=discovery&utm-term=listing&utm-source=cp&aff=escb"
          >
            <button className="register-button">Register Here</button>
          </a>
          <br />
          <h1>Challenge</h1>
          <p>
            Create a product to reimagine an interest, community, or aspect of
            life with an emphasis on sparking joy.
          </p>
        </div>
        <div className="schedule">
          <h1>Schedule</h1>
          <ul>
            <li>9:00am - 11:00am: Check-in</li>
            <li>10:00am - 11:00am: Self-intro & Team Formation on Discord</li>
            <li>
              11:00am - 11:30am: Opening Ceremony: Design Challenge Reveal
            </li>
            <li>11:30am - 12:30pm: Workshop 1: Figma 101</li>
            <li>2:30pm - 3:30pm: Workshop 2: Intro to HTML/CSS</li>
            <li>4:30pm - 5:30pm: Student Design Panel</li>
            <li>5:30pm - 6:30pm: Workshop 3: The Handmade Web </li>
            <li>7:00pm: Final Submissions Due, Game Night</li>
            <li>8:30pm: Closing Ceremony: Guest Speaker + Awards & Prizes</li>
          </ul>
        </div>
        <br />
        <br />
        <div className="faq">
          <h1>FAQ</h1>
          <p style={{ color: "#549F93" }}>Who can attend Spark Joy 2022?</p>
          <p>
            Spark Joy is open to undergraduate students of all levels of design
            experience
          </p>
          <p style={{ color: "#549F93" }}>How will the event work virtually?</p>
          <p>
            We will be using Discord for communication purposes and Zoom for
            workshops! Registration will occur on EventBrite.
          </p>
          <p style={{ color: "#549F93" }}>Are beginners welcome?</p>
          <p>
            Yes! Spark Joy is specifically made to be beginner-friendly! We will
            host a variety of beginner-friendly workshops, such as Figma 101 and
            Intro to HTML/CSS.
          </p>
        </div>
      </div>
    </>
  );
}
