import * as React from "react";

import Layout from "../components/layout";
import Navbar from "../components/navbar";
import Header from "../components/header";
import Footer from "../components/footer";
import Body from "../components/body";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Poiret+One&family=Secular+One&family=Varela+Round&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Layout title="home">
        <Navbar />
        <Header />
        <Body />
        <Footer />
      </Layout>
    </>
  );
};

export default IndexPage;
