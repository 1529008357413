import * as React from "react";

import "./style.css";
import "../../styles/global.css";

export default function Footer() {
  return (
    <>
      <footer></footer>
    </>
  );
}
