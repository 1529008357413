import * as React from "react";

import "./style.css";
import "../../styles/global.css";

import FavIcon from "../../images/logo.png";
import { Helmet } from "react-helmet";

class Layout extends React.Component {
  render() {
    return (
      <html lang="en">
        <head>
          <Helmet>
            <title>{this.props.title}</title>
            <link rel="icon" href={FavIcon} />
          </Helmet>
        </head>
        <body>{this.props.children}</body>
      </html>
    );
  }
}

export default Layout;
