import * as React from "react";
import "./style.css";

const SPARK_WEBSITE_URL = "https://pennspark.org/";

export default function Navbar() {
  return (
    <>
      <div className="navbar">
        <div className="navbar-left">
          <a href={SPARK_WEBSITE_URL} target="_blank">
            Penn Spark Designathon
          </a>
        </div>
        <div className="navbar-right">
          <a href="">Overview</a>
          <a href="">Schedule</a>
          <a href="">FAQ</a>
        </div>
      </div>
    </>
  );
}
